<template>
  <v-select
    v-model="innerValue"
    class=""
    :items="items"
    :label="$t('time.calendar.selectWeekends')"
    item-value="value"
    item-text="text"
    multiple
    :menu-props="{ offsetY: true, overflowY: true, maxHeight: 400 }"
    color="primary"
    clearable
    chips
    small-chips
    dense
    hide-no-data
    :item-disabled="itemDisabled"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip class="pl-0" small v-if="index === 0" color="grey lighten-3">
        <v-avatar class="mr-1" color="primary">
          <span class="white--text font-weight-bold caption">
            {{ item.text.substring(0, 2).toUpperCase() }}
          </span>
        </v-avatar>
        <span>{{ item.text }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text caption">
        (+{{ innerValue.length - 1 }} {{ $t("global.others") }})
      </span>
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-list-item-action>
        <v-checkbox v-model="attrs.inputValue" color="primary" />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
  export default {
    name: "OffdayPicker",
    props: {
      value: Array,
      itemDisabled: Function
    },
    data: vm => ({
      items: [
        {
          text: vm.$t("days.monday"),
          value: 1,
          disabled: false,
          enum: "MONDAY"
        },
        {
          text: vm.$t("days.tuesday"),
          value: 2,
          disabled: false,
          enum: "TUESDAY"
        },
        {
          text: vm.$t("days.wednesday"),
          value: 3,
          disabled: false,
          enum: "WEDNESDAY"
        },
        {
          text: vm.$t("days.thursday"),
          value: 4,
          disabled: false,
          enum: "THURSDAY"
        },
        {
          text: vm.$t("days.friday"),
          value: 5,
          disabled: false,
          enum: "FRIDAY"
        },
        {
          text: vm.$t("days.saturday"),
          value: 6,
          disabled: false,
          enum: "SATURDAY"
        },
        {
          text: vm.$t("days.sunday"),
          value: 0,
          disabled: false,
          enum: "SUNDAY"
        }
      ]
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>
